/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  outline: none !important;
}
body {
  font-size: 16px;
  color: #565267;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: #626262;
}
/* img {
  width: 100%; */
/* } */
.container {
  width: 96%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
a {
  text-decoration: none;
  color: #565267;
}

/* Common CSS */
.popUpHeadPart .popHeading {
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #222222;
}
.popUpHeadPart p {
  margin-top: 10px;
  line-height: 20px;
}
.haasProductPopup {
  background-color: #000;
  height: 100vh;
  position: relative;
}
.haasProductPopup .haasProducWrap {
  background-color: #ffffff;
  background: #ffffff;
  border-radius: 10px;
  max-width: 866px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 95%;
}

/* Login Page */
.haasLoginPage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.haasLoginPage > div {
  width: calc(100% / 2 - 0px);
  height: 100%;
}
.haasLoginPage .rightPart {
  padding: 30px 0px 30px 0px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.popHaasLoginForm .formGroup input {
  width: 100%;
  background: #ffffff;
  border: 1px solid #efefef;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 50px;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #222222 !important;
  padding: 0 15px;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}
.popHaasLoginForm .formGroup input::-webkit-input-placeholder {
  opacity: 1;
}
.popHaasLoginForm .formGroup input::-moz-placeholder {
  opacity: 1;
}
.popHaasLoginForm .formGroup input:-ms-input-placeholder {
  opacity: 1;
}
.popHaasLoginForm .formGroup input::-ms-input-placeholder {
  opacity: 1;
}
.popHaasLoginForm .formGroup input::placeholder {
  opacity: 1;
}
.popHaasLoginForm .formGroup input::-webkit-input-placeholder {
  color: #cdcdcd;
  -webkit-text-fill-color: #cdcdcd;
}
.popHaasLoginForm .formGroup input::-moz-placeholder {
  color: #cdcdcd;
  -webkit-text-fill-color: #cdcdcd;
}
.popHaasLoginForm .formGroup input:-ms-input-placeholder {
  color: #cdcdcd;
  -webkit-text-fill-color: #cdcdcd;
}
.popHaasLoginForm .formGroup input::-ms-input-placeholder {
  color: #cdcdcd;
  -webkit-text-fill-color: #cdcdcd;
}
.popHaasLoginForm .formGroup input::placeholder {
  color: #cdcdcd;
  -webkit-text-fill-color: #cdcdcd;
}
.popHaasLoginForm .formGroup {
  margin-bottom: 15px;
}
.popHaasLoginForm .formCheckBox .passRemember label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #efefef;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  width: 18px;
  height: 18px;
  border-radius: 2px;
}
.popHaasLoginForm .formCheckBox .passRemember input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 8px;
  width: 5px;
  height: 10px;
  border: solid #4cabd0;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.popHaasLoginForm .formCheckBox .passRemember input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  cursor: pointer;
  position: absolute;
  left: 13px;
  z-index: 1;
  opacity: 0;
}
/* Login Page Remember */
.popUpLoginForm {
  margin-top: 25px;
  width: 100%;
}
.popHaasLoginForm .formCheckBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-end;
}
.popHaasLoginForm .formCheckBox .passRemember {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.popHaasLoginForm .formCheckBox .passRemember label {
  font-size: 10px;
  line-height: 21px;
  color: #ababab;
  position: relative;
}
.popHaasLoginForm .formCheckBox .forgotPass a {
  font-weight: 600;
  font-size: 10px;
  line-height: 21px;
  color: #4cabd0;
}
/* Login with Account */
.loginWithOtherAcc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 25px;
  margin-bottom: 25px;
}
.loginWithOtherAcc a {
  width: calc(100% / 2 - 5px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 2px;
  padding: 1px;
}
.loginWithOtherAcc a img {
  width: auto;
  height: 38px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  margin-right: 15px;
}
.loginWithOtherAcc .gmailLog {
  background-color: #d44638;
  margin-right: 10px;
}
.loginWithOtherAcc .gitLog {
  background-color: #222222;
}
.popHaasLoginForm .signUp p {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #222222;
  text-align: center;
}
.popHaasLoginForm .signUp p a {
  color: #4cabd0;
  font-weight: 600;
}
/* Submit Btn */
.popHaasLoginForm .formBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
}
.popHaasLoginForm .formBtn button {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  border: none;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 29px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 10px;
}
.popHaasLoginForm .formBtn .loginCancel {
  background: #efefef;
  color: #4cabd0;
}
.popHaasLoginForm .formBtn .loginSubmit {
  background: #4cabd0;
  color: #fff;
}
.popHaasLoginForm .formBtn .loginSubmitdisabled {
  background:rgba(0, 0, 0, 0.12);
  color:rgba(0, 0, 0, 0.26);
}
.haasLoginPage .rightPart .popUpHeadPart {
  width: 100%;
}
.haasLoginPage .leftPart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.haasLoginPage .leftPart img {
  width: auto;
}
/* Upload Photo */
.haasLoginPage .haasUploadPhoto {
  width: 100%;
  text-align: center;
}
.uploadPhotoBanner {
  background: #ffffff;
  border: 1px solid #efefef;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  padding: 15px;
  position: relative;
}
.uploadPhotoBanner img {
  width: auto;
  height: 132px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  margin-bottom: 15px;
}
.haasLoginPage .haasUploadPhoto .popHaasLoginForm {
  margin-left: auto;
  margin-right: auto;
  width: 48%;
}
.uploadPhotoBanner p {
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}
.uploadPhotoBanner .inputUploadImg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.haasTeamsHead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}
.haasTeamsHead .teamsHaasBox {
  font-size: 16px;
  line-height: 19px;
  color: #222222;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 55px;
}
.haasTeamsHead .teamsHaasBox svg {
  margin-right: 10px;
}
.productTabbingPart {
  margin-top: 50px;
  margin-left: 20px;
}
.productTabbingPart .multitabPart {
  border-bottom: 2px solid #efefef;
  padding-bottom: 30px;
}
.productTabbingPart .multitabPart ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.productTabbingPart .multitabPart ul li a {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}
.productTabbingPart .multitabPart ul li a:hover,
.productTabbingPart .multitabPart ul li .active {
  color: #4cabd0;
}
.productTabbingPart .productContentPart {
  margin-top: 25px;
}
.productTabbingPart .productContentPart .tabbingMainHeading {
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #222222;
}
/* Haas Select Team Mate */
.haasLoginPage .haasSelectTeamMate {
  width: 50%;
  margin: 0 auto;
}
.haasLoginPage .haasSelectTeamMates {
  width: 60%;
  margin: 0 auto;
}
.addbutton {
  display: flex;
  align-items: center;
}
.add_button {
  margin-left: 10px;
  /* width: 80px;
  height: 40px; */
  width: 75px;
  height: 47px;
  border-radius: 5px;
  flex-wrap: wrap;
  /* color: #fff;
  background: #4cabd0; */
  border: none;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
}
.add_Button {
  color: #fff;
  background: #4cabd0;
}
.haasLoginPage .haasSelectTeamMate .popUpHeadPart {
  padding-top: 30px;
  text-align: center;
}
.haasLoginPage .popHaasLoginForm .haasSelectTeamMateForm {
  /* margin-bottom: 250px; */
  position: relative;
}
.haasLoginPage .popHaasLoginForm .haasSelectTeamMateForm .multiple_emails-ul {
  position: absolute;
  top: 60px;
  display: flex;
  flex-wrap: wrap;
  left: -30px;
}
.haasLoginPage
  .popHaasLoginForm
  .haasSelectTeamMateForm
  .multiple_emails-ul
  .multiple_emails-email {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
  padding: 6px 40px 6px 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  position: relative;
}
.haasLoginPage .popHaasLoginForm .haasSelectTeamMateForm .multiple_emails-ul {
  position: absolute;
  top: 60px;
  display: flex;
  flex-wrap: wrap;
  left: 50%;
  justify-content: center;
  align-items: center;
  width: 700px;
  transform: translateX(-50%);
}
.haasLoginPage
  .popHaasLoginForm
  .haasSelectTeamMateForm
  .multiple_emails-ul
  .multiple_emails-email {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
  padding: 6px 35px 6px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  position: relative;
  margin: 8px;
}
.haasLoginPage
  .popHaasLoginForm
  .haasSelectTeamMateForm
  .multiple_emails-ul
  .multiple_emails-email
  .multiple_emails-close {
  display: inline-block;
  position: absolute;
  right: 21px;
  top: 10px;
}
.haasLoginPage
  .popHaasLoginForm
  .haasSelectTeamMateForm
  .multiple_emails-ul
  .multiple_emails-email
  .multiple_emails-close:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 10px;
  background-color: #ababab;
  transform: rotate(45deg);
}
.haasLoginPage
  .popHaasLoginForm
  .haasSelectTeamMateForm
  .multiple_emails-ul
  .multiple_emails-email
  .multiple_emails-close:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 10px;
  background-color: #ababab;
  transform: rotate(-45deg);
}

@media (max-width: 1023px) {
  .loginWithOtherAcc a {
    font-size: 12px;
  }
  .loginWithOtherAcc a img {
    margin-right: 10px;
  }
  .haasLoginPage .haasSelectTeamMate {
    width: 70%;
  }
}

/* Header */
.siteHeader {
  border-bottom: 1px solid #efefef;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
  top: 0;
}
.siteHeader .headerWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.siteHeader .headerMenu {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  border: 2px solid #4cabd0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.siteHeader .headerMenu:hover {
  overflow: visible;
}
.siteHeader .headerMenu > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #fff;
  width: 54px;
  height: 54px;
  border-radius: 100px;
}
.siteHeader .headerMenu > a img {
  width: 54px;
  height: 54px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 100px;
}
.siteHeader .headerLogo a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.siteHeader .headerMenu .headerPhotoSubMenu {
  position: absolute;
  top: 57px;
  right: 0px;
  z-index: 1;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
  width: 120px;
  opacity: 0;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}
.siteHeader .headerMenu:hover .headerPhotoSubMenu {
  opacity: 1;
}
.siteHeader .headerMenu .headerPhotoSubMenu li a {
  font-size: 12px;
  line-height: 17px;
  color: #000000;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}
.siteHeader .headerMenu .headerPhotoSubMenu li a:hover {
  color: #4cabd0;
}
.siteHeader .headerMenu .headerPhotoSubMenu li {
  margin-bottom: 10px;
}
.siteHeader .headerMenu .headerPhotoSubMenu li:last-child {
  margin-bottom: 0;
}
.pageContentSection {
  margin-top: 81px;
}

/* SideBar */
.sideBarNav {
  width: 12%;
}
.sideBarNav .sideBarWrap {
  position: fixed;
  width: 215px;
  border-right: 1px solid #efefef;
  height: 100vh;
}
.sideBarNav .sideBarHead {
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
  margin-top: 15px;
}
.sideBarNav .sideBarMainMenu ul li {
  margin: 30px 0;
}
.sideBarNav .sideBarHead a {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
  width: 100%;
  display: inline-block;
  padding: 10px 0;
}
.sideBarNav .sideBarHead a svg {
  margin-left: 10px;
}
.sideBarNav .sideBarHead .sideBarHeadSubMenu {
  position: absolute;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1;
  left: 0;
  top: 35px;
  right: 0;
  width: 250px;
  padding: 25px;
  opacity: 0;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}
.sideBarNav .sideBarHead .sideBarHeadSubMenu li a {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding: 0;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}
.sideBarNav .sideBarHead .sideBarHeadSubMenu li a:hover {
  color: #4cabd0;
}
.sideBarNav .sideBarHead .sideBarHeadSubMenu li {
  margin-bottom: 20px;
}
.sideBarNav .sideBarHead .sideBarHeadSubMenu li:last-child {
  margin-bottom: 0;
}
.sideBarNav .sideBarHead:hover .sideBarHeadSubMenu {
  opacity: 1;
}
.sideBarNav .sideBarHead:hover {
  overflow: visible;
}
.sideBarNav .sideBarMainMenu ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #ababab;
  position: relative;
  font-weight: 500;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}
.sideBarNav .sideBarMainMenu ul li a svg {
  margin-right: 20px;
  width: 25px !important;
  height: 25px !important;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}
.sideBarNav .sideBarMainMenu ul li.menuSelect a,
.sideBarNav .sideBarMainMenu ul li:hover a {
  color: #222222;
}
.sideBarNav .sideBarMainMenu ul li.menuSelect a svg path,
.sideBarNav .sideBarMainMenu ul li:hover a svg path {
  fill: #4cabd0;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}
.sideBarNav .sideBarMainMenu ul li.menuSelect a:before,
.sideBarNav .sideBarMainMenu ul li:hover a:before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #4cabd0;
  border-radius: 110px;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

/* Right Content Part */
.rightContentMainPart {
  width: 88%;
  margin-left: auto;
  padding-left: 15px;
  padding-top: 15px; /* border-left:1px solid #EFEFEF;*/
  padding-bottom: 15px;
}
.contentWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.rightContentMainPart .bannerImg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.rightContentMainPart .bannerImg img {
  height: 400px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}
.rightContentMainPart .bannerHeadingPart .productLogo img {
  width: 80px;
  height: 50px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}
.rightContentMainPart .bannerHeadingPart {
  position: relative;
  padding-left: 170px;
  padding-top: 30px;
}
.rightContentMainPart .bannerHeadingPart .productLogo {
  position: absolute;
  top: -60px;
  width: 120px;
  height: 120px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 20px;
}
.rightContentMainPart .bannerHeadingPart .proMainHeading {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #222222;
}

/* Participate Teams */
.challPartiTeamMainWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.challPartiTeamMainWrap .challMainBox {
  width: calc(100% / 6 - 40px);
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  text-align: center;
  padding: 30px 10px 23px;
  margin: 20px;
}
.challPartiTeamMainWrap .challMainBox .partiImg {
  width: 130px;
  height: 130px;
  margin: 0 auto;
  border-radius: 100px;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.challPartiTeamMainWrap .partAccepted .partiImg {
  border: 5px solid #b9e5f3;
}
.challPartiTeamMainWrap .challMainBox .partiImg img {
  width: 110px;
  height: 110px;
  border-radius: 100px;
}
.challPartiTeamMainWrap .challMainBox .partiName {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 15px;
  margin-bottom: 5px;
  word-break: break-all;
}
.challPartiTeamMainWrap .challMainBox .partiEmail {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #626262;
  word-break: break-all;
}
.challPartiTeamMainWrap .challMainBox .partiInvitation {
  margin-top: 25px;
}
.challPartiTeamMainWrap .partAccepted .partiInvitation {
  opacity: 0;
}
.challPartiTeamMainWrap .challMainBox .partiInvitation a {
  display: inline-block;
  font-size: 16px;
  line-height: 19px;
}
.challPartiTeamMainWrap .challMainBox .partiInvitation .accepted {
  color: #009246;
}
.challPartiTeamMainWrap .challMainBox .partiInvitation .rejected {
  color: #f44336;
}
.challPartiTeamMainWrap .challMainBox .partiInvitation .invited {
  color: #407bff;
}
/* Add Participate */
/* .challPartiTeamMainWrap .addParticipate{display:flex;align-items:center;justify-content:center;background:#FFFFFF;border:5px solid #EFEFEF;box-sizing:border-box;border-radius:10px;height: 280px;} */
.challPartiTeamMainWrap .challMainBox:last-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 5px solid #efefef;
  height: 280px;
  cursor: pointer;
}
.challPartiTeamMainWrap .addParticipate .addPlusIcon {
  width: 110px;
  height: 110px;
  background-color: #f8f8f8;
  border-radius: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* Create Stack */
.createStackPage .myStack {
  margin-bottom: 40px;
  margin-top: 15px;
}
.createStackPage .stackMainHeading {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #4cabd0;
  margin-bottom: 20px;
  margin-left: 10px;
}
.createStackPage .stackBoxMainWrap .stackBox {
  width: calc(100% / 6 - 20px);
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
}
.createStackPage .stackBoxMainWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.createStackPage .stackBoxMainWrap .stackBox .stackImg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.createStackPage .stackBoxMainWrap .stackBox .stackImg img {
  width: auto;
}
.createStackPage .stackBoxMainWrap .stackBox .stackBoxHeading {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-top: 15px;
  margin-bottom: 15px;
  min-height: 44px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
}
.createStackPage .stackBoxMainWrap .stackBox p {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #626262;
  min-height: 76px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
}
.createStackPage .stackBoxMainWrap .stackBox .createStackLink {
  margin-top: 10px;
}
.createStackPage .stackBoxMainWrap .stackBox .createStackLink a {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #4cabd0;
  vertical-align: middle;
}
.createStackPage .stackBoxMainWrap .stackBox .createStackLink a svg {
  margin-left: 10px;
  vertical-align: middle;
}

@media (max-width: 1500px) {
  .sideBarNav {
    width: 18%;
  }
  .rightContentMainPart {
    width: 82%;
  }
  .sideBarNav .sideBarWrap {
    width: 230px;
  }

  /* Participate Teams */
  .challPartiTeamMainWrap .challMainBox {
    width: calc(100% / 4 - 40px);
  }

  /* Create Stack */
  .createStackPage .stackBoxMainWrap .stackBox {
    width: calc(100% / 4 - 20px);
  }
}

@media (max-width: 1279px) {
  .sideBarNav {
    width: 22%;
  }
  .rightContentMainPart {
    width: 78%;
  }
  .sideBarNav .sideBarWrap {
    width: 212px;
  }

  /* Right Content Part */
  .rightContentMainPart .bannerImg img {
    height: 270px;
  }
  .rightContentMainPart .bannerHeadingPart .productLogo {
    top: -45px;
    width: 80px;
    height: 80px;
  }
  .rightContentMainPart .bannerHeadingPart .productLogo img {
    width: 50px;
    height: auto;
  }
  .rightContentMainPart .bannerHeadingPart {
    padding-left: 120px;
  }
  .rightContentMainPart .bannerHeadingPart .proMainHeading {
    font-size: 25px;
    line-height: 25px;
  }
  .haasTeamsHead {
    margin-top: 15px;
  }
  .productTabbingPart {
    margin-top: 30px;
    margin-left: 0;
  }
  .haasTeamsHead .teamsHaasBox {
    margin-right: 20px;
  }
  .productTabbingPart .multitabPart ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .productTabbingPart .multitabPart ul li {
    margin: 10px 15px;
  }
  .productTabbingPart .multitabPart {
    padding-bottom: 15px;
  }

  /* Participate Teams */
  .challPartiTeamMainWrap .challMainBox {
    width: calc(100% / 3 - 20px);
    margin: 10px;
  }

  /* Create Stack */
  .createStackPage .stackBoxMainWrap .stackBox {
    width: calc(100% / 3 - 10px);
    padding: 20px 15px;
    margin: 5px;
  }
  .createStackPage .myStack {
    margin-bottom: 20px;
  }
  .createStackPage .stackMainHeading {
    margin-bottom: 10px;
  }
}

@media (max-width: 1023px) {
  .sideBarNav {
    width: 30%;
  }
  .rightContentMainPart {
    width: 70%;
  }

  /* Participate Teams */
  .challPartiTeamMainWrap .challMainBox {
    width: calc(100% / 2 - 20px);
    margin: 10px;
  }

  /* Create Stack */
  .createStackPage .stackBoxMainWrap .stackBox {
    width: calc(100% / 2 - 10px);
  }
}
